import { Carousel, disabledCarouselStyles } from '@loveholidays/design-system';
import React from 'react';

import { ContentBox } from '../ContentBox/ContentBox';
import { HolidayLink } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { SectionContainer } from '@Components/SectionContainer';

export interface HolidayLinksProps extends ClassNameProps {
  holidayLinks: HolidayLink[];
}

export const HolidayLinks: React.FC<HolidayLinksProps> = ({ className, holidayLinks }) => {
  if (!holidayLinks?.length) {
    return null;
  }

  return (
    <SectionContainer
      data-id="holiday-links"
      className={className}
    >
      <Carousel
        maxItemsOnShow={[1.3, 2, 4]}
        sx={disabledCarouselStyles([1, 2, 4], [4, 2, 1])}
      >
        {holidayLinks.map(({ ...item }, i) => (
          <ContentBox
            key={i}
            {...item}
            iconColor="iconDefault"
            iconBackgroundColor="backgroundLightsubtle"
            contentStyles={{
              borderStyle: null,
              backgroundColor: 'backgroundWhite',
            }}
            sectionStyles={{
              display: 'flex',
              alignItems: 'center',
            }}
            textStyles={{
              marginBottom: null,
            }}
            trackingAction="holiday-links"
          />
        ))}
      </Carousel>
    </SectionContainer>
  );
};
