import { Label, Link } from '@loveholidays/design-system';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { Icon } from '@Components/Icon/Icon';
import { WithClickTracking } from '@Core/tracking/WithClickTracking';
import { ColorsPalette } from '@UX/themes/types';

export interface ContentBoxProps extends ClassNameProps {
  title: string;
  description?: string;
  url: string;
  linkText?: string;
  icon: string;
  iconColor?: keyof ColorsPalette;
  iconBackgroundColor: keyof ColorsPalette;
  trackingAction: string;
  contentStyles?: SxStyleProp;
  sectionStyles?: SxStyleProp;
  textStyles?: SxStyleProp;
}

export const ContentBox: React.FC<ContentBoxProps> = ({
  title,
  description,
  url,
  icon,
  iconColor,
  iconBackgroundColor,
  className,
  trackingAction,
  contentStyles,
  sectionStyles,
  textStyles,
}) => {
  const iconCmp = (
    <Icon
      name={icon}
      width={30}
      height={56}
      wrapperStyles={{
        height: '56px',
        width: '56px',
        minWidth: '56px',
        textAlign: 'center',
        backgroundColor: iconBackgroundColor,
        borderRadius: 'rounded',
        color: iconColor,
        marginRight: '2xs',
      }}
    />
  );

  return (
    <WithClickTracking action={trackingAction}>
      <Link
        href={url}
        className={className}
        sx={{
          display: 'block',
          borderWidth: 'outlinedStrokeWeight',
          borderStyle: 'solid',
          borderColor: 'strokeDarkneutral',
          padding: 'l',
          borderRadius: '_chipped',
          textDecoration: 'none',
          color: 'textDefault',
          '&:hover, :visited': {
            color: 'textDefault',
            'section > p:first-of-type': {
              textDecoration: 'underline',
            },
          },
          ...contentStyles,
        }}
        aria-label={title}
      >
        <article
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {iconCmp}
          <section
            sx={{
              marginRight: '2xs',
              flexGrow: 1,
              ...sectionStyles,
            }}
          >
            <Label
              variant="largebold"
              sx={{
                marginBottom: '3xs',
                color: 'textDefault',
                ...textStyles,
              }}
            >
              {title}
            </Label>

            {!!description && (
              <Label
                variant="small"
                sx={{
                  marginBottom: '3xs',
                  color: 'textDimmedmedium',
                }}
              >
                {description}
              </Label>
            )}
          </section>
        </article>
      </Link>
    </WithClickTracking>
  );
};
